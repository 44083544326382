import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment'
import {CustomerFeature} from "../models/customerFeature";
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class CustomerFeaturesService {

  private customerActiveFeaturesSubject = new BehaviorSubject<any>(null);
  customerActiveFeatures$ = this.customerActiveFeaturesSubject.asObservable();

  constructor(private http: HttpClient) { }

  setCustomerFeatures(data: any) {
    this.customerActiveFeaturesSubject.next(data);
  }

  getCustomerActiveFeatures() {
    return this.customerActiveFeaturesSubject.value;
  }

  public getCustomerFeatures(): Observable<CustomerFeature[]> {
    return this.http.get<CustomerFeature[]>(`${environment.yomaApiURL}/admin/customer/features`);
  }

  toggleFeature(id: string, toggled_off: boolean) {
    let encoded = Buffer.from(id).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/admin/customer/features/${encoded}`, {}, {params: {toggled_off: toggled_off}})
  }
}
