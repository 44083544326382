import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'yomaDateFormat',
  standalone: true
})
export class YomaDateFormatPipe implements PipeTransform {

  transform(value: string | number | Date, dateFormat: string): string {
    if (typeof value === 'string') {
      return format(parseISO(value), dateFormat);
    } else if (typeof value === 'number') {
      return format(value * 1000, dateFormat);
    }
    else {
      return format(value, dateFormat);
    }
  }
}
