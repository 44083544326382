<div class="page-content" *ngIf="!isLoading">
    <div nz-page-header class="page-header">
      <div class="header-container">
        <div class="menu-wraper">
          <a class="header-logo" routerLink="">
            <img nz-page-header-avatar src="assets/logo.png" alt="img" />
          </a>
        </div>
        <nz-space class="menu-space">
          <nz-space class="menu-lg">
            <a
              href="https://yoma-track-trace.helpjuice.com/"
              target="_blank"
              class="btn-header link-btn"
              *nzSpaceItem
              nz-button
              nzType="primary"
            >
              Support
            </a>
            <button
              class="btn-header"
              *nzSpaceItem
              nz-button
              nzType="primary"
              nzDanger
              (click)="login()"
            >
              login
            </button>
          </nz-space>
          <button class="menu-button" nz-button nzType="text" (click)="open()">
            <span nz-icon nzType="menu-fold" class="font-2x"></span>
          </button>
          <nz-drawer
            [nzClosable]="true"
            [nzVisible]="visible"
            [nzPlacement]="placement"
            nzTitle="Menu"
            (nzOnClose)="close()"
          >
            <ng-container *nzDrawerContent>
              <ul nz-menu (click)="close()">
                <li nz-menu-item routerLink="/home"><h3>Home</h3></li>
              </ul>
              <div class="drawer-buttons">
                <a
                  href="https://yoma-track-trace.helpjuice.com/"
                  target="_blank"
                  nz-button
                  nzType="primary"
                >
                  Support
                </a>
                <button
                  class="login-button"
                  nz-button
                  nzType="primary"
                  nzDanger
                  (click)="login()"
                >
                  login
                </button>
              </div>
            </ng-container>
          </nz-drawer>
        </nz-space>
      </div>
    </div>
    <div class="page">
        <nz-skeleton [nzActive]="true" [nzLoading]="!vehicleDetails"></nz-skeleton>
        <div *ngIf="vehicleDetails">
            <nz-card class="vehicle-banner" >
                <div class="card-header">
                    <h1 class="banner-title">
                        Ihr Fahrzeug
                    </h1>
                </div>
                <div class="content">
                    <div class="info">
                        <div>
                            <!-- cardealer -->
                            <div><strong>Von: {{vehicleDetails.cardealer_name}}</strong></div>
                            <div>{{ vehicleDetails.cardealer_name }}</div>
                            <div>{{ vehicleDetails.cardealer_street }} {{ vehicleDetails.cardealer_street_number }}</div>
                            <!-- production_order_date -->
                            <div style="padding-top: 2em;"><strong>Bestellt am</strong></div>
                            <div>{{vehicleDetails.cardealer_order_date ? (vehicleDetails.cardealer_order_date | yomaDateFormat: 'dd.MM.yyyy') : '&mdash;' }}</div>
                        </div>
                        <div>
                            <div><strong>An: {{vehicleDetails.cardealer_customer_name ?? '&mdash;'}}</strong></div>
                            <div>{{vehicleDetails.current_shipping_address_name}}</div>
                            <div>{{vehicleDetails.current_shipping_address_street}} {{vehicleDetails.current_shipping_address_street_number}}</div>
                            <div style="padding-top: 2em;"><strong>Geplante Ankunft</strong></div>
                            <div>{{vehicleDetails.delivery_date_cardealer_planned_update ? (vehicleDetails.delivery_date_cardealer_planned_update | yomaDateFormat: 'dd.MM.yyyy') : '&mdash;'}}</div>
                        </div>
                    </div>
                    <div class="car-image">
                        <img src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{vehicleDetails.vehicle_brand}}&modelFamily={{vehicleDetails.vehicle_model_normalized}}&angle=28&zoomType=fullscreen&paintdescription={{vehicleDetails.vehicle_color | text2Slug}}" width="300"/>
                        <!-- <img alt="car-img" ngSrc="assets/car-template.png" height="200" width="300"> -->
                    </div>
                </div>
                <div class="rails-wrap">
                    <img alt="rails" class="rails" src="assets/grey-rails.png" height="100" width="110">
                </div>
            </nz-card>
            <div class="vehicle-details">
                <div>
                    <h3 nz-typography>Fahrzeug</h3>
                    <div><strong>Modell</strong></div>
                    <div>{{ vehicleDetails.vehicle_model_line ?? '&mdash;' }}</div>
                    <nz-divider></nz-divider>
                    <div><strong>VIN</strong></div>
                    <div>{{ vehicleDetails.vehicle_identification_number ?? '&mdash;' }}</div>
                    <nz-divider></nz-divider>
                    <div><strong>Farbe</strong></div>
                    <div>{{ vehicleDetails.vehicle_color ?? '&mdash;' }} <span class="color"></span></div>
                    <nz-divider></nz-divider>
                    <nz-divider></nz-divider>
                </div>
                <div>
                    <!-- cardealer -->
                    <h3 nz-typography>Bestellung</h3>
                    <div><strong>Bestellendes Autohaus</strong></div>
                    <div>{{ vehicleDetails.cardealer_name }}</div>
                    <div>{{ vehicleDetails.cardealer_street }} {{ vehicleDetails.cardealer_street_number }}</div>
                    <div>{{ vehicleDetails.cardealer_zip_code }} {{ vehicleDetails.cardealer_location }}</div>
                    <nz-divider></nz-divider>
                    <!-- cardealer_sales_id -->
                    <div><strong>Auftrags durchführendes Autohaus</strong></div>
                    <div>{{ vehicleDetails.cardealer_sales_address_name }}</div>
                    <div>{{ vehicleDetails.cardealer_sales_address_street }} {{ vehicleDetails.cardealer_sales_address_street_number }}</div>
                    <div>{{ vehicleDetails.cardealer_sales_address_zip_code }} {{ vehicleDetails.cardealer_sales_address_location }}</div>
                    <nz-divider></nz-divider>
                    <div><strong>Zieladresse</strong></div>
                    <!-- current_shipping_id -->
                    <div>{{ vehicleDetails.cardealer_shipping_address_name }}</div>
                    <div>{{ vehicleDetails.cardealer_shipping_address_street }} {{ vehicleDetails.cardealer_shipping_address_street_number }}</div>
                    <div>{{ vehicleDetails.cardealer_shipping_address_zip_code }} {{ vehicleDetails.cardealer_shipping_address_location }}</div>
                    <nz-divider></nz-divider>
                </div>
            </div>
        </div>
        <div class="full-data-container" style="margin-bottom: 30px">
            <span class="full-data-item" *ngFor="let item of vehicleDetails | keyvalue">
            <span class="data-item-value"> {{item.key}}</span>
            <span class="data-item-description"> {{item.value}}</span>
            </span>
        </div>
    </div>
</div>
