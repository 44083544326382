import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import de from '@angular/common/locales/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NgZorroAntdModule } from './modules/ng-zorro-antd.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { FleetPredictionComponent } from './components/fleet-prediction/fleet-prediction.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { VehicleBrandComponent } from './components/vehicle-brand/vehicle-brand.component';
import { environment as env } from '../environments/environment';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FleetPredictionChartComponent } from './components/fleet-prediction-chart/fleet-prediction-chart.component';
import { VehicleModelComponent } from './components/vehicle-model/vehicle-model.component';
import { FleetPredictionBannerComponent } from './components/fleet-prediction-banner/fleet-prediction-banner.component';
import { LayoutComponent } from './components/layout/layout.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { NewsComponent } from './components/news/news.component';
import { VehicleInfoModalComponent } from './components/vehicle-info-modal/vehicle-info-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { Angulartics2Module } from 'angulartics2';
import { FavoriteVehiclesModule } from './directives/favorite-vehicle.module';
import { FeaturesComponent } from './pages/features/features.component';
import { FronteggAppModule } from '@frontegg/angular';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ImporterAccountsComponent } from './components/importer-accounts/importer-accounts.component';
import { KeyAccountsComponent } from './components/key-accounts/key-accounts.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SliderComponent } from './components/slider/slider.component';
import {NewsPageComponent} from "./pages/news-page/news-page.component";
import { SearchInputModule } from './components/search-input/search-input.module';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import {TranslatePipe} from "./pipes/translate.pipe";
import {SellingFeaturesCardComponent} from "./components/selling-features-card/selling-features-card.component";

registerLocaleData(de);

const fronteggConfig = {
  contextOptions: {
    baseUrl: env.fronteggBaseUrl,
    clientId: env.fronteggClientId,
  },
  authOptions: {
    // keepSessionAlive: true // Uncomment this in order to maintain the session alive,
    hostedLoginOptions: {
      loadUserOnFirstLoad: true
    }
  },
  hostedLoginBox: true,
  localizations: {
    en: {
      loginBox: {
        login: {
          title: "Anmelden",
          emailInputLabel: "E-Mail",
          emailInputPlaceholder: "name@beispiel.de",
          continue: "Weitermachen",
        },
      },
    },
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    FleetPredictionComponent,
    PageHeaderComponent,
    VehicleBrandComponent,
    FleetPredictionChartComponent,
    VehicleModelComponent,
    FleetPredictionBannerComponent,
    LayoutComponent,
    PageFooterComponent,
    NewsComponent,
    VehicleInfoModalComponent,
    FeaturesComponent,
    RegisterPageComponent,
    NewsPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    NzBadgeModule,
    ScrollingModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    NgApexchartsModule,
    AgGridModule,
    InfiniteScrollModule,
    ClipboardModule,
    Angulartics2Module.forRoot(),
    FavoriteVehiclesModule,
    FronteggAppModule.forRoot(fronteggConfig),
    ImporterAccountsComponent,
    KeyAccountsComponent,
    YouTubePlayerModule,
    SliderComponent,
    SearchInputModule,
    DashboardCardComponent,
    TranslatePipe,
    SellingFeaturesCardComponent
  ],
  providers: [
    { provide: NZ_I18N, useValue: de_DE },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
