import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexGrid,
  ApexPlotOptions,
  ApexStates,
  ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import {ExtendedVehicleModel, PredictionModel} from "../../models/vehicleModel";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'tt-fleet-prediction-chart',
  templateUrl: './fleet-prediction-chart.component.html',
  styleUrls: ['./fleet-prediction-chart.component.scss']
})
export class FleetPredictionChartComponent implements OnInit, OnChanges{
  @ViewChild("chartObj") chart!: ChartComponent;
  @Input() currentVehicleData: PredictionModel = {} as PredictionModel;
  public chartOptions: ChartOptions;

  from: number = 0;
  mid: number =  0;
  to: number = 0;
  predictionColor: string = ""
  chartData: any = [];

  constructor() {
    this.chartOptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        type: "rangeBar",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      tooltip:{
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: 6,
          borderRadius: 0,
        }
      },
      title: {
        text: "Fleet Prediction Chart"
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          },
        }
      },
      xaxis: {
        tickPlacement: "on",
        axisBorder: {
          show: false
        },
        labels: {
          hideOverlappingLabels: true,
          rotateAlways: false,
          rotate: 0,
          style: {
            fontFamily: "inherit",
            colors: '#8f9198'
          }
        },

      },
      yaxis: {
        labels: {
          show: false
        }
      },
    };
  }
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes){
      this.calculateChartData();
      this.chartOptions.series[0].data = this.chartData
      this.chart?.updateSeries([{data: this.chartData}]);
    }
  }

  setChartPeriod(){
    this.from = this.currentVehicleData.from ? + this.currentVehicleData.from.toFixed() : 0
    this.mid = this.currentVehicleData.mid ? + this.currentVehicleData.mid.toFixed() : 0
    this.to = this.currentVehicleData.to ? + this.currentVehicleData.to.toFixed() : 0
  }

  public calculateChartData() {
    if(this.currentVehicleData.id) {

      let stepsLeftCounts = 0;
      let stepsLeftValue = 0;

      let stepsRightCounts = 0;
      let stepsRightValue = 0;
      this.chartData = [];
      this.setChartPeriod()

      switch (this.currentVehicleData.quality) {
        case 1: this.predictionColor = "#07B674"; break
        case 2: this.predictionColor = "#00A3FF"; break
        case 3: this.predictionColor = "#FDB749"; break
      }

      if (this.mid) {
        this.chartData.push({x: this.mid + "W", y: [5, 95], fillColor: this.predictionColor})
        if (this.from) {
          stepsLeftCounts = +this.mid - +this.from
          stepsLeftValue = 80 / (stepsLeftCounts)
          // if((stepsLeftCounts || stepsRightCounts) > 5 && window.innerWidth < 700){
          //   for (let i = 2; i <= stepsLeftCounts; i+=2) {
          //     this.chartData.unshift({
          //       x: this.mid - i + "W",
          //       y: [+(5 + (stepsLeftValue * i) / 2).toFixed(), +(100 - (stepsLeftValue * i) / 2 - 5).toFixed()],
          //       fillColor: this.predictionColor
          //     })
          //   }
          // } else {
            for (let i = 1; i <= stepsLeftCounts; i++) {
              this.chartData.unshift({
                x: this.mid - i + "W",
                y: [+(5 + (stepsLeftValue * i) / 2).toFixed(), +(100 - (stepsLeftValue * i) / 2 - 5).toFixed()],
                fillColor: this.predictionColor
              })
            }
            this.chartData.unshift({x: +this.from.toFixed() - 1 + "W", y: [0, 0]})
          // }
        }
        if (this.to) {
          stepsRightCounts = +this.to - +this.mid
          stepsRightValue = 80 / (stepsRightCounts)
          // if((stepsLeftCounts || stepsRightCounts) > 5 && window.innerWidth < 700){
          //   for (let i = 2; i <= stepsRightCounts; i+=2) {
          //     this.chartData.push({
          //       x: this.mid + i + "W",
          //       y: [+(5 + (stepsRightValue * i) / 2).toFixed(), +(100 - (stepsRightValue * i) / 2 - 5).toFixed()],
          //       fillColor: this.predictionColor
          //     })
          //   }
          // } else {
            for (let i = 1; i <= stepsRightCounts; i++) {
              this.chartData.push({
                x: this.mid + i + "W",
                y: [+(5 + (stepsRightValue * i) / 2).toFixed(), +(100 - (stepsRightValue * i) / 2 - 5).toFixed()],
                fillColor: this.predictionColor
              })
            }
            this.chartData.push({x: +this.to.toFixed() + 1 + "W", y: [0, 0]})
          // }
        }
      }
      this.chartOptions.series[0].data = this.chartData
    } else return
  }

}
