import { Injectable, inject } from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { Buffer } from 'buffer';
import { ImporterAccount } from '../models/importer';

@Injectable({
  providedIn: 'root'
})
export class ImportersService {

  http = inject(HttpClient)

  getImporters(): Observable<any> {
    return this.http.get(`${environment.yomaApiURL}/admin/customer/importers`)
  }

  addImporter(importerAccount: ImporterAccount): Observable<any> {
    return this.http.post(`${environment.yomaApiURL}/admin/customer/importers`, importerAccount)
  }

  deleteImporter(importerAccountId: string): Observable<any> {
    importerAccountId = Buffer.from(importerAccountId).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/admin/customer/importers/${importerAccountId}`)
  }

  getSupplierSystems(): Observable<any> {
    return this.http.get(`${environment.yomaApiURL}/admin/customer/importers/supplierSystems`)
  }

  verifyCredentials(importerAccountId: string): Observable<any> {
    importerAccountId = Buffer.from(importerAccountId).toString('base64')
    return this.http.post(`${environment.yomaApiURL}/admin/customer/importers/${importerAccountId}/verify`, {})
  }

  verificationStatus(id: string): Observable<any> {
    id = Buffer.from(id).toString('base64');
    return this.http.get(`${environment.yomaApiURL}/admin/customer/importers/${id}/verify`)
  }
}
