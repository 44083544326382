import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {VehicleBrands} from "../models/VehicleBrand";
import {HttpClient} from "@angular/common/http";
import {ExtendedVehicleModel, PredictionModels} from "../models/vehicleModel";
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class VehicleModelService {

  constructor(private http: HttpClient) { }

  public getVehicleModelByID(modelID: string): Observable<PredictionModels> {
    return this.http.get<PredictionModels>(`${environment.fleetMonitorUrl}/models/${modelID}`)
  }

}
