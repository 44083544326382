import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'text2Slug',
  standalone: true
})
export class Text2SlugPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value.toLowerCase().replace(/ /g, '-') : ""
  }

}
