<div class="page">
  <div class="info-panel">
    <h1 nz-typography class="info-header hide-mobile">Willkommen !</h1>
    <h1 nz-typography class="info-header display-mobile">Dein Dashboard</h1>
    <div class="circle"></div>
    <img src="assets/blue-pin.svg" class="pin hide-mobile" alt="">
    <img src="assets/blue-rails.png" class="rails hide-mobile" alt="">
    <div class="header-spacer hide-mobile"></div>
    <div class="info-content hide-mobile">
      Behalte dein Überblick über alle von dir bestellten Fahrzeuge mit unserer einfachen Onlinesicht.
      Jederzeit wissen wann welches Auto ausgeliefert wird.
    </div>
<!--    <nz-skeleton-->
<!--      [nzActive]="true"-->
<!--      [nzLoading]="isLoading"-->
<!--    ></nz-skeleton>-->
    <div class="quick-search-wrapper hide-mobile" *ngIf="hasFeature('feature-vehicles')">
      <h4 nz-typography class="info-search">
        Verwende die Schnellsuche, um dein Fahrzeug zu finden:
      </h4>
      <div class="quick-search">
        <div
          class="quick-search-item"
        >
          <div nz-typography nzType="secondary" class="search-item-label">
            Marke
          </div>
          <nz-select
            class="info-select"
            [(ngModel)]="selectedBrand"
            nzBorderless="true"
            nzPlaceHolder="Beliebig"
          >
            <nz-option *ngFor="let brand of vehicleService.vehicleBrands" nzValue="{{brand}}" nzLabel="{{brand}}"></nz-option>
          </nz-select>
        </div>
        <div
          class="quick-search-item"
        >
          <div nz-typography nzType="secondary" class="search-item-label">
            Hersteller Bestell-Nr.
          </div>
          <input nz-input [(ngModel)]="selectedOrderId" nzBorderless="true" placeholder="Beliebig"/>
        </div>
        <div
          class="quick-search-item"
        >
          <div nz-typography nzType="secondary" class="search-item-label">
            Auftragstyp
          </div>
          <nz-select
            class="info-select"
            [(ngModel)]="selectedOrderType"
            nzBorderless="true"
            nzPlaceHolder="Beliebig"
          >
            <nz-option *ngFor="let orderType of vehicleService.vehicleOrderTypes" nzValue="{{orderType}}" nzLabel="{{orderType | translate}}"></nz-option>
          </nz-select>
        </div>
        <div class="quick-search-item">
          <div nz-typography nzType="secondary" class="search-item-label">
            Kundenname
          </div>
          <input nz-input nzBorderless="true" [(ngModel)]="selectedCustomerName" placeholder="Beliebig"/>
        </div>
        <button
          class="quick-search-button"
          nz-button
          nzType="primary"
          nzDanger
          (click)="setFilterData()"
        >
          Suche
        </button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div *ngIf="hasFeature('feature-vehicles')" class="dashboard-cards-container">
      @if(!isMobileView) { <h2>Dein Dashboard</h2> }
      <div class="dashboard-cards">
        <tt-dashboard-card
          color="#01B574"
          title="Anstehende Lieferungen"
          class="dashboard-card"
          type="pending"
          [isViewMoreBtnVisible]=true
        ></tt-dashboard-card>
        <tt-dashboard-card
          color="#01B574"
          title="Meine Favoriten"
          class="dashboard-card"
          type="favourites"
          [isViewMoreBtnVisible]=true
        ></tt-dashboard-card>
        <tt-dashboard-card
          color="#fe4c23"
          title="Update: Verzögerung"
          class="dashboard-card"
          type="changes_to_delivery_date"
          sort="desc"
          [isViewMoreBtnVisible]=true
        ></tt-dashboard-card>
        <tt-dashboard-card
          color="#fe4c23"
          title="Update: Frühzeitig"
          class="dashboard-card"
          type="changes_to_delivery_date"
          sort="asc"
          [isViewMoreBtnVisible]=true
        ></tt-dashboard-card>
      </div>
    </div>

    <nz-skeleton
      *ngIf="isLoading"
      [nzActive]="true"
      [nzLoading]="isLoading"
      style="margin: 50px 0"
    ></nz-skeleton>

    <h2 *ngIf="featureTeasers?.length" class="selling-features-title">Hol das meiste aus
      Track & Trace raus!</h2>

    <div class="selling-features-wrapper" *ngIf="featureTeasers?.length">
      <tt-selling-features-card *ngFor="let feature of featureTeasers" [feature]="feature"></tt-selling-features-card>
    </div>
    <div class="news-section" *ngIf="hasFeature('feature-news-feed')">
      <tt-news></tt-news>
<!--      <button nz-button nzSize="large" nzType="primary" routerLink="/news" style="margin: 30px 0; ">-->
<!--        Mehr laden-->
<!--      </button>-->
    </div>
  </div>
</div>
