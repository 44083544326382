<div class="selling-features-card" [routerLink]="'/landing/' + feature?.alias">
  <div class="selling-features-card-content">
    <img [src]="'assets/' + teaserImage" alt="" height="215" width="404">
    <div class="card-text-content">
      <h4 nz-typography class="selling-features-card-title">{{feature?.name}}</h4>
      <span nz-typography class="selling-features-card-subtitle">{{feature?.name_subtitle}}</span>
      <h4 nz-typography class="selling-features-card-price">{{feature?.price == 0? 'Kostenlos': feature?.price + '€ / Monat'}}</h4>
      <span nz-typography nzType="danger" class="additional-info-text">{{feature?.pricing_information}}</span>
    </div>
    <a class="selling-features-btn" [routerLink]="'/landing/' + feature?.alias">
      Mehr Infos <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
    </a>
  </div>
</div>
