import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';


@Component({
  selector: 'tt-vehicle-info-modal',
  templateUrl: './vehicle-info-modal.component.html',
  styleUrls: ['./vehicle-info-modal.component.scss']
})
export class VehicleInfoModalComponent {

  constructor(private modal: NzModalService) {
  }

  @Input() data: any;
  @Input() isVisible: boolean = false;
  @Output() onClose = new EventEmitter<boolean>();

  footer: any = null;

  handleClose(): void {
    this.onClose.emit(false);
  }

}
