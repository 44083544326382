<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="Full Vehicle Info"
  (nzOnOk)="handleClose()"
  (nzOnCancel)="handleClose()"
  [nzClosable]="true"
  [nzFooter]="footer"
  [nzWidth]="'90%'"
  class="full-description-modal"
>
  <ng-container *nzModalContent>
    <div class="vehicle-card-content">
      <div class="card-content-row">
        <div class="vehicle-card-header">{{data.vehicle_brand}} - {{ data.vehicle_model_line }}
          - {{ data.vehicle_color }}</div>
        <div class="vehicle-card-item">{{ data.vehicle_identification_number }}(FIN)</div>
      </div>
      <div class="card-content-row">
        <div class="vehicle-card-item">{{ data.production_order_id }}(Hersteller Bestellnummer)</div>
        <div class="vehicle-card-item">{{ data.cardealer_vehicle_order_type }}</div>
      </div>
      <div class="card-content-row">
        <div class="vehicle-card-item">{{ data.cardealer_customer_name }}</div>
      </div>
      <div class="full-data-container">
        <div class="full-data-item" *ngFor="let item of data | keyvalue">
          <div class="data-item-value"> {{item.key}}</div>
          <div class="data-item-description"> {{item.value}}</div>
        </div>


        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">Bestellendes Autohaus:</div>-->
        <!--          <div class="data-item-description">-->
        <!--            {{ data.cardealer_name }}<br>-->
        <!--            {{ data.cardealer_street }} {{data.cardealer_street_number}}<br>-->
        <!--            {{data.cardealer_zip_code}}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">{{data.delivery_date_cardealer_planned_update}}</div>-->
        <!--          <div class="data-item-description">Letztes Anlieferungsdatum an der Zieladresse</div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">{{ data.delivery_date_cardealer }}</div>-->
        <!--          <div class="data-item-description">Fahrzeug an aktueller Zieladresse eingetroffen:</div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">{{ data.yoma_production_end_date }}</div>-->
        <!--          <div class="data-item-description">Produktion:</div>-->
        <!--        </div>-->

        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">Verkäufer:</div>-->
        <!--          <div class="data-item-description">{{ data.cd_name_salesman }}({{ data.cd_salesman_code }})</div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">Ende Tracking Status:</div>-->
        <!--          <div class="data-item-description">{{ data.yoma_state_tracking_end_node_key }}</div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">{{ data.yoma_state_tracking_end_date }}</div>-->
        <!--          <div class="data-item-description">Ende Tracking Status Datum:</div>-->
        <!--        </div>-->
        <!--        <div class="full-data-item">-->
        <!--          <div class="data-item-value">Aktuelle Zieladresse:</div>-->
        <!--          <div class="data-item-description">-->
        <!--            {{ data.current_shipping_address_name }}<br>-->
        <!--            {{ data.current_shipping_address_street }} {{ data.current_shipping_address_street_number }} <br>-->
        <!--            {{ data.current_shipping_address_zip_code }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </ng-container>
</nz-modal>
