import {Component, inject, OnInit} from '@angular/core';
import {FormControl, FormRecord, NonNullableFormBuilder} from "@angular/forms";
import {NzModalService} from "ng-zorro-antd/modal";
import {CustomerFeaturesService} from "../../services/customer-features.service";
import {CustomerFeature} from "../../models/customerFeature";
import {Subscription} from "rxjs";
import { FronteggAuthService, FronteggAppService } from '@frontegg/angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {UserInfoService} from "../../services/user-info.service";
import {ConfettiService} from "../../services/confetti.service";

@Component({
  selector: 'tt-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements  OnInit{

  private subscription = new Subscription()
  modal = inject(NzModalService)
  customerFeaturesService = inject(CustomerFeaturesService);

  customersFeatures: CustomerFeature[] = [];
  isLoading = false;
  isModalLoading = false;
  fronteggAuthService = inject(FronteggAuthService);
  userInfoService = inject(UserInfoService);
  confettiService = inject(ConfettiService);
  user:any | undefined
  features: any;

  constructor(private fb: NonNullableFormBuilder, private fronteggAppService: FronteggAppService) {
    this.userInfoService.getUserFeatures.subscribe( res => {
      this.features = res;
    })
    this.fronteggAuthService.user$.pipe(takeUntilDestroyed()).subscribe(res => this.user = res)
  }

  ngOnInit(): void {
    this.getCustomerFeatures();
  }

  toggleValue(feature: CustomerFeature){

    const modal = this.modal.confirm({
      nzTitle: `Möchtest du dieses Feature wirklich ${feature.toggled_off? 'aktivieren' : 'deaktivieren'}?`,
      nzOkText: 'Ja',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.toggleFeature(feature),
      nzCancelText: 'Nein',
    });
  }
  toggleFeature(feature: any){
    this.isModalLoading = true;
    this.subscription.add(
      this.customerFeaturesService.toggleFeature(feature.pk, !feature.toggled_off).subscribe( (res: any) => {
        let features = res.filter((feature: CustomerFeature) => feature.toggled_off == false)
        let featureAliases: string[] = [];
        features.map((item: { alias: string; }) => featureAliases.push(item.alias))
        this.customersFeatures = res;
        this.updateCustomerFeatures();
        this.userInfoService.setFeature(featureAliases);
        if(feature.toggled_off){
          this.confettiService.celebrate();
        }
        this.isModalLoading = false;
      })
    )
  }

  updateCustomerFeatures(){
    this.customerFeaturesService.setCustomerFeatures(this.customersFeatures);
  }

  getCustomerFeatures() {
    this.isLoading = true;
    this.subscription.add(
      this.customerFeaturesService.getCustomerFeatures().subscribe(res => {
        this.customersFeatures = res;
        this.isLoading = false
      })
    )
  }
}
