import {Component, Input, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { NzIconModule} from "ng-zorro-antd/icon";
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {RouterModule} from "@angular/router";
import {CustomerFeature} from "../../models/customerFeature";


@Component({
  selector: 'tt-selling-features-card',
  standalone: true,
  imports: [CommonModule, NzTypographyModule, RouterModule, NzIconModule, NgOptimizedImage],
  templateUrl: './selling-features-card.component.html',
  styleUrl: './selling-features-card.component.scss'
})
export class SellingFeaturesCardComponent implements OnInit{
  @Input() feature: CustomerFeature | undefined

  teaserImage: string = ''

  ngOnInit() {
    this.setImages();
  }

  setImages(){
    switch (this.feature?.alias) {
      case 'feature-vehicles': this.teaserImage = 'vehicles.png';
        break;
      case 'feature-keyaccount': this.teaserImage = 'key-account.png';
        break;
      case 'feature-new-vehicle-monitor': this.teaserImage = 'new-vehicles.png';
        break;
      case 'feature-expert-view': this.teaserImage = 'expert-view.png';
        break;
      case 'feature-news-feed': this.teaserImage = 'news.png';
        break;
      default: this.teaserImage = 'vehicles.png';
        break;
    }
  }

}
