import { Injectable } from '@angular/core';
import { Angulartics2, Angulartics2Segment } from 'angulartics2'
import {environment} from "../../environments/environment";

export const ACTIONS = {
  login_failed: 'Login Failed',
  login_submitted: 'Login Submitted',
  login_succeeded: 'Login Succeeded',
  logout_clicked: 'Logout Clicked',
  neuwagenmonitor_bunner_unlock_now_btn_clicked: 'Neuwagenmonitor bunner: unlock now button clicked',
  news_load_more_button_clicked: 'News: Load more button clicked',
  footer_impresum_button_clicked: 'Footer: Impresum button clicked',
  footer_cookies_button_clicked: 'Footer: Cookies button clicked',
  footer_datenschutz_button_clicked: 'Footer: Datenschutz button clicked',
  header_profile_button_clicked: 'Header: Profile button clicked',
  header_developer_button_clicked: 'Header: Developer button clicked',
  header_support_button_clicked: 'Header: Support button clicked',
  neuwagenmonitor_search_button_clicked: 'Neuwagenmonitor: Search button clicked',
  neuwagenmonitor_model_selected: 'Neuwagenmonitor: Model selected',
  neuwagenmonitor_brand_selected: 'Neuwagenmonitor: Brand selected',
  neuwagenmonitor_fuel_type_changed: 'Neuwagenmonitor: Fuel type changed',
  dashboard_page_loaded: ' Dashboard page loaded',
  neuwagenmonitor_page_loaded: 'Neuwagenmonitor page loaded',
  meine_fahrzeuge_page_loaded: 'Meine Fahrzeuge page loaded',
  expertenansicht_page_loaded: 'Expertenansicht page loaded',
}

export interface TrackingSettings {
  optOutAll: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private _config: TrackingSettings = {
    optOutAll: false,
  }

  constructor(
    private _angulartics2Segment: Angulartics2Segment,
    private _angulartics2: Angulartics2,
  ) {
    this._config.optOutAll = this._angulartics2.settings.developerMode

    _angulartics2Segment.startTracking()

  }

  trackEvent(action: string, properties: any) {
    if (!this._config.optOutAll && action) {
      this._trackEvent(action, properties)
    }
  }

  trackUser(user: any) {
    if (!this._config.optOutAll && user) {
      this._trackUser(user)
    }
  }

  private _trackEvent(action: string, properties: any) {
    this._angulartics2Segment.eventTrack(action, properties)
  }
  private _trackUser(user: any) {
    // const currentTrackedUser = JSON.parse(window.localStorage.getItem('ajs_user_id'))

    if (user !== user.email && environment.production) {
      this._angulartics2Segment.unsetUserProperties()

      // user = user || new UserModel({})
      const userProps = {
        userId: user.email, // Caution: this parameter must be calles exactly like this: 'userId' !!!
      }
      this._angulartics2Segment.setUserProperties(userProps)
    }
  }
}
