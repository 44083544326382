<nz-input-group nzSearch [nzSuffix]="suffixIconButton" class="search-input-group">
  <input
    data-tesdid="search-button"
    type="text"
    class="search-input"
    id="searchInput"
    nz-input
    placeholder="{{placeholder}}"
    [(ngModel)]="searchInputValue"
    [ngModelOptions]="{standalone: true}"
    (keyup.enter)= "setSearchValue()"
  />
</nz-input-group>
<ng-template #suffixIconButton>
  <button
    data-tesdid="search-button"
    nz-button
    nzType="primary"
    nzSize="large"
    class="search-button"
    nzSearch
    nzDanger
    (click)="setSearchValue()"
  >
    Suche <span nz-icon nzType="search"></span>
  </button>
</ng-template>
