import { Component, OnInit, inject } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { FronteggAuthService } from '@frontegg/angular';
import { NzDrawerModule, NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Subscription, map, switchMap } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';
import { Buffer } from 'buffer';
import { CommonModule } from '@angular/common';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzCardModule } from 'ng-zorro-antd/card';
import { YomaDateFormatPipe } from 'src/app/pipes/yoma-date-format.pipe';
import { Text2SlugPipe } from 'src/app/pipes/text2-slug.pipe';
import { NzDividerModule } from 'ng-zorro-antd/divider';

@Component({
  selector: 'tt-my-vehicle',
  templateUrl: './my-vehicle.component.html',
  styleUrls: ['./my-vehicle.component.scss'],
  standalone: true,
  imports: [ CommonModule, NzSpaceModule, NzDrawerModule, NzSkeletonModule, NzCardModule, YomaDateFormatPipe, Text2SlugPipe, NzDividerModule ]
})
export class MyVehicleComponent implements OnInit {
  private subscription = new Subscription()
  route = inject(ActivatedRoute)
  vehicleService = inject(VehicleService)
  fronteggAuthService = inject(FronteggAuthService)
  router = inject(Router)
  vehicleDetails: any
  visible = false;
  placement: NzDrawerPlacement = 'right';
  vehicleId: string | undefined;
  isLoading = true;

  ngOnInit(): void {
    this.subscription.add(
      this.route.params.pipe(
        map((param: Params) => { this.vehicleId = param['vehicleId']; return param['vehicleId'] }),
        switchMap(id => {
          if(localStorage.getItem('HOSTED_LOGIN_VERIFIER_KEY')){
            this.router.navigateByUrl(`/vehicle/${id}`).then(()=> {this.isLoading = false});
          }
          else {
            this.isLoading = false;
          }
          return this.vehicleService.getNoAuthVehicleDetails(id)})
      ).subscribe((res:any) => {
        this.vehicleDetails = res;
      })
    )
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  login(): void {
    localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', `/vehicle/${this.vehicleId}`);
    this.fronteggAuthService.loginWithRedirect({ prompt: 'login' })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
