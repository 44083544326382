import {Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {VehicleBrandService} from "../../services/vehicle-brand.service";
import {VehicleModel} from "../../models/vehicleModel";
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-vehicle-brand',
  templateUrl: './vehicle-brand.component.html',
  styleUrls: ['./vehicle-brand.component.scss']
})
export class VehicleBrandComponent implements OnInit {

  vehicleData?: VehicleModel[] = [];
  vehicleBrand?: string = "";
  filteredVehicleData?: VehicleModel[] = [];
  brandID: string | null = "";
  trackingService= inject(TrackingService);
  isLoading = true;

  constructor(private route: ActivatedRoute, private router: Router, private vehicleBrandService: VehicleBrandService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe().subscribe(data =>{
      this.brandID = data.get('brandID')
    })
    this.vehicleBrandService.getVehicleBrandInfo(this.brandID as string).subscribe(data => {
      this.vehicleData = data?.models;
      this.filteredVehicleData = data?.models;
      this.vehicleBrand = data.brand;
      this.isLoading = false;
    })
  }

  selectModel(model: any){
    this.trackingService.trackEvent(ACTIONS.neuwagenmonitor_model_selected, {value: model})
  }
  sortColumn(a: any, b: any) {
    return b.model.localeCompare(a.model)
  }

  setFilteredVehicleData = (searchValue: string) => {
    this.filteredVehicleData = this.vehicleData?.filter((model) => model.model?.toLowerCase().includes(searchValue))
  }
}
