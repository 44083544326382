import { Component } from '@angular/core';

@Component({
  selector: 'tt-fleet-prediction-banner',
  templateUrl: './fleet-prediction-banner.component.html',
  styleUrls: ['./fleet-prediction-banner.component.scss']
})
export class FleetPredictionBannerComponent {

}
