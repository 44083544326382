import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService implements OnDestroy {

  private subscription = new Subscription()
  // private userSettings = new BehaviorSubject({ settings: {}, timestamp: ''});
  private userSettings = new ReplaySubject(1)
  private http = inject(HttpClient)

  constructor() {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe
  }

  public saveUserSettings(data: any) {
    this.http.post(`${environment.yomaApiURL}/user/settings`, data).subscribe(res => this.userSettings.next(res))
  }

  public setUserSettings(data: any) {
    this.userSettings.next(data)
  }

  public getUserSettings(): Observable<any> {
    return this.userSettings
  }
}
