import { Directive, DoCheck, HostListener, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FavoriteVehicleService } from '../services/favorite-vehicle.service';
import { Observable, Subject, Subscription, mergeMap } from 'rxjs';
import { Buffer } from 'buffer';
import { FronteggAuthService } from '@frontegg/angular';
import {UserInfoService} from "../services/user-info.service";

@Directive({
  selector: '[myFavoriteVehicles]'
})
export class MyFavoriteVehicleDirective implements OnInit, OnDestroy {
  subscription = new Subscription()
  favoriteVehicles = new Subject<any[]>()
  favoriteVehicleService = inject(FavoriteVehicleService)
  fronteggAuthService = inject(FronteggAuthService)
  userInfoService = inject(UserInfoService)
  user: any | null = null
  list: any[] = []

  ngOnInit(): void {
    this.userInfoService.getUser.pipe(
      mergeMap(user => {
        if(user) {
          this.user = user
          return this.favoriteVehicleService.getFavorites()
        }
        return new Observable
      })
    ).subscribe(res => {
      this.list = res.map((element: string) => Buffer.from(element, 'base64').toString('utf-8'))
      this.favoriteVehicles.next(this.list)
    })

    this.subscription.add(
      this.favoriteVehicleService.favoriteChanged.subscribe(res => {
        if (res) {
          this.favoriteVehicleService.getFavorites().subscribe(
            res => {
              this.list = res.map((element: string) => Buffer.from(element, 'base64').toString('utf-8'))
              this.favoriteVehicles.next(this.list)
            }
          )
        }
      })
    )
  }

  public get getFavoriteList(): Observable<any[]> {
    return this.favoriteVehicles
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}


@Directive({
  selector: '[ttFavoriteVehicle]',
  exportAs: 'favoriteVehicle'
})
export class FavoriteVehicleDirective implements OnInit, OnDestroy, DoCheck {
  @Input() vehicleDetails: any
  subscription = new Subscription()
  favoriteVehicleService = inject(FavoriteVehicleService)
  favorite: any
  myFavoriteVehicles = inject(MyFavoriteVehicleDirective)
  favoriteVehicles: any[] = []
  isLoading = false

  ngOnInit(): void {
    this.subscription.add(
      this.myFavoriteVehicles.getFavoriteList.subscribe(
        res => {
          this.favoriteVehicles = res;
          this.favorite = this.isFavorite(this.vehicleDetails)
        }
      )
    )
    this.subscription.add(
      this.favoriteVehicleService.favoriteChanged.subscribe(res => {
        if (res) {
          this.favorite = this.isFavorite(this.vehicleDetails)
        }
      })
    )
  }

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    this.setFavorite(event)
  }

  setFavorite($event: Event) {
    $event.preventDefault()
    this.isLoading = true
    if(this.favorite != undefined) {
      this.favoriteVehicleService.deleteFavorite(this.favorite).subscribe(res => {
        this.favoriteVehicleService.favoriteChanged.emit(true)
        this.isLoading = false
      })
    } else {
      this.favoriteVehicleService.setFavorite(this.vehicleDetails.id).subscribe(res => {
        this.favoriteVehicleService.favoriteChanged.emit(true)
        this.isLoading = false
      })
    }
  }
  isFavorite(vehicle: any) {
    return this.favoriteVehicles.find(elem => elem == vehicle.id)
  }

  ngDoCheck(): void {
    this.favoriteVehicles = this.myFavoriteVehicles.list
    this.favorite = this.isFavorite(this.vehicleDetails)
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe()
  }
}
