import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  fb = inject(NonNullableFormBuilder)
  // userService = inject(UserLoginService)
  errorMessage: string = "";
  router = inject(Router);
  trackingService = inject(TrackingService)
  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
    remember: FormControl<boolean>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    remember: [true]
  });

  ngOnInit() {
    // this.userService.isAuthenticated(this);
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      let user = {
        username: this.validateForm.value.userName ?? "" ,
        password: this.validateForm.value.password ?? ""
      }
      // this.userService.authenticate(user.username, user.password, this);
      this.trackingService.trackEvent(ACTIONS.login_submitted, {})
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
    this.trackingService.trackEvent(ACTIONS.login_submitted, {})
  }
}
