import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {VehicleService} from "../../services/vehicle.service";
import {VehicleStatusType} from 'src/app/models/vehicleStatus';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";
import {FronteggAuthService} from '@frontegg/angular';
import { UserInfoService } from 'src/app/services/user-info.service';
import {ImportersService} from "../../services/importers.service";
import {CustomerFeature} from "../../models/customerFeature";
import {CustomerFeaturesService} from "../../services/customer-features.service";
import {DateRangeFilter, TermFilter, VehicleFilters} from "../../models/filters";
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'tt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private subscription = new Subscription()
  vehicleService = inject(VehicleService)
  veicleStatusType = VehicleStatusType
  fronteggAuthService = inject(FronteggAuthService)
  trackingService = inject(TrackingService)
  customerFeaturesService = inject(CustomerFeaturesService);
  router = inject(Router)
  breakpointObserver = inject(BreakpointObserver)

  selectedBrand = "";
  selectedOrderId = "";
  selectedOrderType = "";
  selectedCustomerName = "";

  vehicleFilters: VehicleFilters;

  featureTeasers: CustomerFeature[] | undefined;

  isLoading = false
  userInfoService = inject(UserInfoService)
  features: any;
  allFeatures: CustomerFeature[] = [];
  isMobileView: boolean = false

  constructor() {
    this.vehicleFilters = {
      filter: [],
      filterMap: new Map<string, TermFilter | DateRangeFilter>(),
      search: "",
      wildcard_search: [],
      status: {
        status: "",
        sort: ""
      },
      archive: "active"
    }
    this.userInfoService.getUserFeatures.subscribe( res => {
      this.features = res;
      this.isLoading = false
    })
  }

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.WebPortrait]).subscribe((state: BreakpointState) => {
        this.isMobileView = state.matches;
      })
    )
    this.trackingService.trackEvent(ACTIONS.dashboard_page_loaded, {})
    this.getAllFeatures();
  }

  hasFeature(feature: any){
    if(this.features){
      return this.features?.indexOf(feature) != -1;
    } else return false
  }


  setFeatures(){
    this.featureTeasers = this.allFeatures.filter(item=> !this.features.includes(item.alias)).filter(item =>item.show_on_frontpage)
  }

  getAllFeatures() {
    this.isLoading = true;
    this.subscription.add(
      this.customerFeaturesService.getCustomerFeatures().subscribe(res => {
        this.allFeatures = res;
        this.setFeatures();
        this.isLoading = false
      })
    )
  }

  setFilterData() {
    if (this.selectedOrderId) {
      this.vehicleFilters.filterMap.set('production_order_id', {attribute: 'production_order_id', values: [this.selectedOrderId]})
    }
    if (this.selectedBrand) {
      this.vehicleFilters.filterMap.set('vehicle_brand', {attribute: 'vehicle_brand', values: [this.selectedBrand]})
    }
    if (this.selectedCustomerName) {
      this.vehicleFilters.wildcard_search.push({attribute: 'cardealer_customer_name', value: this.selectedCustomerName})
    }
    if (this.selectedOrderType) {
      this.vehicleFilters.filterMap.set('cardealer_vehicle_order_type', {attribute: 'cardealer_vehicle_order_type', values: [this.selectedOrderType]})
    }
    this.vehicleService.setFilters(this.vehicleFilters);
    this.router.navigateByUrl('vehicles')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
