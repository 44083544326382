<div class="chart-wrapper" >
  <!-- <h3 class="chart-title">{{chartTitle}}</h3> -->
  <h3 class="chart-title">{{currentVehicleData.brand}} {{currentVehicleData.model}}</h3>
  <p class="chart-description">Vorhersage zum Übergabezeitpunkt</p>
  <div class="fleet-prediction-chart">
    <apx-chart #chartObj
     [series]="chartOptions.series"
     [chart]="chartOptions.chart"
     [xaxis]="chartOptions.xaxis"
     [yaxis]="chartOptions.yaxis"
     [grid]="chartOptions.grid"
     [plotOptions]="chartOptions.plotOptions"
     [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </div>
</div>
