<div class="page">
  <tt-fleet-prediction-banner/>
  <div class="page-content">
    <div *ngIf="isLoading" class="loader-container">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
    <div *ngIf="!isLoading" class="vehicle-model-wrapper">
      <nz-breadcrumb class="breadcrumb">
        <nz-breadcrumb-item><a routerLink="*">Home</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a routerLink="/neuwagenmonitor">Neuwagenmonitor</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a routerLink="/neuwagenmonitor/{{vehicleBrandID}}">{{selectedPredictionModel.brand}}</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>{{selectedPredictionModel.model}}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <p class="description-text" *ngIf="!(allVehicles.size <= 1)">
        Bitte wählen Sie eine der folgenden Kraftstoffarten für das von Ihnen gewählte Fahrzeug
      </p>
      <div class="vehicle-model-content">
        <nz-radio-group [(ngModel)]="selectedPredictionModel" class="radio-group" nzSize="large"
                        (ngModelChange)="modelChange($event)">
          <label data-testid="fuel-type" *ngFor="let f of allVehicles | keyvalue" nz-radio-button
                 [nzValue]="f.value">{{f.key? f.key : "ANDERE"}}</label>
        </nz-radio-group>
        <div class="prediction-wrapper">
          <div class="chart-container">
            <div class="prediction-legend-container">
              <div class="prediction-legend-item">
                <div class="legend-circle good"></div>
                <span class="legend-description">Gute Vorhersage</span>
              </div>
              <div class="prediction-legend-item">
                <div class="legend-circle orientation"></div>
                <span class="legend-description">Orientierung</span>
              </div>
              <div class="prediction-legend-item">
                <div class="legend-circle sufficient"></div>
                <span class="legend-description">Ausreichende Vorhersage</span>
              </div>
            </div>
            <tt-fleet-prediction-chart
              [currentVehicleData]="selectedPredictionModel"
            ></tt-fleet-prediction-chart>
          </div>
          <div class="prediction-container">
            <div class="prediction-calendar">
              <div class="prediction-content">
                <span class="calendar-title">Vorhersage bei der heutigen Bestellung</span>
                <span class="calendar-subtitle">Kalenderwoche</span>
                <span class="calendar-text">{{predictionModelData.from}}
                  -{{predictionModelData.to}} {{predictionModelData.year}}</span>
              </div>
              <div class="prediction-estimation">
                <div class="estimation-content">
                  <span class="estimation-title">Geschätztes Datum</span>
                  <span class="estimation-text">{{predictionModelData.dateFrom}}
                    -{{predictionModelData.dateTill}} </span>
                </div>
              </div>
            </div>
            <div class="prediction-trend">
              <div class="trend-wrapper">
                <div>Entwicklung der Auftragsvorlaufzeit</div>
                <div class="trend-container">
                  <div class="trend-content">
                    <div class="column">
                      <div>{{predictionModelData.previousMonth}}W</div>
                      <div [style.height]="predictionModelData.firstColumn" class="pillar">
                        <div class="month">{{getMonth(-1)}}</div>
                      </div>
                    </div>
                    <div class="column">
                      <div>{{predictionModelData.currnetMonth}}W</div>
                      <div [style.height]="predictionModelData.secondColumn" class="pillar">
                        <div class="month">{{getMonth()}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
