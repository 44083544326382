<div class="login">
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
        <div class="logo">
            <img nz-page-header-avatar src="assets/logo.png" alt="img"/>
        </div>
        <nz-form-item>
            <nz-form-control nzErrorTip="Please input your username!">
                <nz-input-group nzPrefixIcon="user">
                <input type="text" nz-input formControlName="userName" placeholder="Username" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control nzErrorTip="Please input your Password!">
                <nz-input-group nzPrefixIcon="lock">
                <input type="password" nz-input formControlName="password" placeholder="Password" />
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
        <div nz-typography nzType="danger" nz-row class="login-form-margin" *ngIf="errorMessage">{{errorMessage}}</div>
        <div nz-row class="login-form-margin">
            <div nz-col [nzSpan]="12">
                <label nz-checkbox formControlName="remember">
                <span>Remember me</span>
                </label>
            </div>
            <div nz-col [nzSpan]="12">
                <a class="login-form-forgot">Forgot password</a>
            </div>
        </div>
        <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Log in</button>
    </form>
</div>