<div class="page">
  <nz-card class="fleet-prediction-banner">
    <div class="card-content">
      <img alt="fleet prediction" src="assets/neuwagenmonitor-logo_white.svg" class="fleet-prediction-image">
      <p class="fleet-prediction-description">
        Unser Neuwagenmonitor unterstützt Sie dabei, die Lieferzeiten für
        neue Fahrzeuge aller Marken und Modelle bis zur Übergabe effizient zu planen.
      </p>
    </div>
    <img alt="rails" class="rails" ngSrc="assets/orange-rails.png" height="319" width="361">
  </nz-card>

  <div class="fleet-prediction-content page-content">
    <nz-breadcrumb class="fleet-prediction-breadcrumb">
      <nz-breadcrumb-item><a routerLink="/">Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>Neuwagenmonitor</nz-breadcrumb-item>
    </nz-breadcrumb>

    <p class="search-text">Die folgende Übersicht zeigt die voraussichtlichen Laufzeiten von der
      Fahrzeugbestellung bis zur Fahrzeugauslieferung</p>
    <tt-search-input id="brand-search" placeholder="Suche nach einer bestimmten Marke" (searchValue)="this.setFilteredBrandData($event)"/>
    <div *ngIf="isLoading" class="loader-container">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
    <div *ngIf="!isLoading" class="predictions-cards">
      <div *ngFor="let item of filteredBrandsData" class="fleet-prediction-card">
        <nz-card data-testid="prediction-card" class="prediction-card" [routerLink]="['/neuwagenmonitor', item.id]" (click)="selectBrand(item.brand)">
          <h3 class="card-title">{{item.brand}}</h3>
          <span class="card-description">Frühestes Lieferdatum {{item.from?.toFixed()}} Wochen </span>
          <div class="models-count">{{item.models}} Verfügbare Modelle</div>
        </nz-card>
      </div>
    </div>
  </div>
</div>
