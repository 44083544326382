import { CommonModule, JsonPipe } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform, TemplateRef, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { EMPTY, Observable, Subject, delay, expand, take, takeUntil } from 'rxjs';
import { ImporterAccount } from 'src/app/models/importer';
import { ImportersService } from 'src/app/services/importers.service';

@Pipe({
  name: 'verificationStatus',
  standalone: true
})
export class VerificationStatusPipe implements PipeTransform {
  repeat$$ = new Subject<void>();
  importersService = inject(ImportersService)

  transform(accountId: string): Observable<any> {
    return new Observable((observer) => {
      if(!accountId) { this.repeat$$.complete(); observer.complete() }
      this.importersService.verificationStatus(accountId).pipe(
        expand((res:any) => {
          if(res == 'unverified') {
            return this.importersService.verificationStatus(accountId).pipe(delay(60000))
          }
            this.repeat$$.complete()
            return EMPTY
        }),
        takeUntil(this.repeat$$)
      ).subscribe(res => {
        observer.next(res)
      })
    })
  }
}

@Component({
  selector: 'tt-importer-accounts',
  standalone: true,
  imports: [JsonPipe, CommonModule, FormsModule, NzButtonModule, NzFormModule, NzModalModule, NzSelectModule, NzInputModule, NzDividerModule, NzIconModule, NzCardModule, NzSwitchModule, NzSkeletonModule, NzEmptyModule, NzTypographyModule, NzToolTipModule, VerificationStatusPipe],
  templateUrl: './importer-accounts.component.html',
  styleUrl: './importer-accounts.component.scss'
})
export class ImporterAccountsComponent implements OnInit {

  importersService = inject(ImportersService)
  modal = inject(NzModalService);

  accounts = new Array<ImporterAccount>()
  supplierSystem: any | undefined
  availableSystems = new Array()
  existingCredential: ImporterAccount | undefined
  importerAccount: ImporterAccount | undefined

  accLoading = true
  isAvailableSystemsLoading = false
  error:any | undefined
  deleteTextValue = ""
  accountCRUD = false

  ngOnInit(): void {
    this.getImporterAccounts()
    this.getSupplierSystems()
  }

  getImporterAccounts() {
    this.accLoading = true
    this.importersService.getImporters().pipe(take(1)).subscribe((res: any) => {
      this.accounts = res
      this.accLoading = false
    })
  }

  getSupplierSystems() {
    this.isAvailableSystemsLoading = true
    this.importersService.getSupplierSystems().pipe(take(1)).subscribe(res => {
      this.availableSystems = res
      this.isAvailableSystemsLoading = false
    })
  }


  addImporterAccount(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Konfiguration des Importers hinzufügen',
      nzContent: tplContent,
      nzFooter: tplFooter,
      // nzData: this.customer,
    });
  }

  deleteImporterAccount(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item: any) {
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Importer löschen',
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzData: item,
    });
    modal.afterClose.subscribe(() => {
      this.deleteTextValue = ''
      this.error = undefined
    });
  }

  selectSupplierSystem(event: any) {
    this.importerAccount = {} as ImporterAccount
    this.importerAccount.supplier_partner_segment = this.supplierSystem.supplier_partner_segment
    this.importerAccount.supplier_system_partner_id = this.supplierSystem.supplier_system_partner_id
    this.importerAccount.supplier_system_auth_id = this.supplierSystem.supplier_system_auth_id
    this.existingCredential = this.accounts
      .find(elem =>
        (elem.supplier_system_auth_id == event.supplier_system_auth_id) && (event.supplier_system_auth_id != "madax"))
    if(this.existingCredential && this.existingCredential.supplier_system_auth_id != "madax") {
      this.importerAccount.credentials_id = this.existingCredential.credentials_id
    }
    else {
      this.importerAccount.secrets = {} as {username: string, password: string, otp_secret_key: string}
    }
  }

  closeModal(ref: NzModalRef) {
    this.supplierSystem = undefined
    this.error = undefined
    this.importerAccount = undefined
    ref.close()
  }

  newImporterAccount(ref: NzModalRef) {
    this.accountCRUD = true
    if(this.importerAccount) {
      this.importersService.addImporter(this.importerAccount).pipe(take(1)).subscribe(
        {
          next: (res: any) => {
            this.importersService.verifyCredentials(res.id).pipe(take(1)).subscribe()
            this.getSupplierSystems()
            this.getImporterAccounts()
            this.supplierSystem = undefined
            ref.close()
            this.accountCRUD = false
          },
          error: (error: any) => {
            this.error = error.error
            this.accountCRUD = false
          },
          complete: () => {
            this.accountCRUD = false
          }
        }
      )
    }
  }

  isCreateDisabled(): boolean {
    let disabled = true
    if(this.supplierSystem) {
      if(this.supplierSystem.supplier_system_auth_id === 'grp') {
        disabled = !(this.importerAccount?.supplier_system_auth_id && this.importerAccount?.secrets?.username && this.importerAccount?.secrets?.password && this.importerAccount?.secrets?.otp_secret_key || this.importerAccount?.credentials_id)
      } else {
        disabled = !(this.importerAccount?.supplier_system_auth_id && this.importerAccount?.secrets?.username && this.importerAccount?.secrets?.password)
      }
    }
    return disabled
  }

  delete(ref: NzModalRef) {
    this.accountCRUD = true
    let importerAccountId = ref.getConfig().nzData.id
    this.importersService.deleteImporter(importerAccountId).pipe(take(1)).subscribe({
      next: (res: any) => {
        this.accountCRUD = false
        this.getSupplierSystems()
        this.getImporterAccounts()
        ref.close()
      },
      error: (err: any) => {
        this.error = err.error
        this.accountCRUD = false
      },
      complete: () => {
        this.accountCRUD = false
      }
    })
  }

}
