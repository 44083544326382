<div class="slide">
  <button nz-button  nzShape="circle" nzType="default" (click)="carousel.pre()" class="left">
    <span nz-icon nzType="chevron_left" nzTheme="outline"></span>
  </button>
  <nz-carousel #carousel>
    @for (item of images; track $index) {
    <div nz-carousel-content>
      <img
        [src]="item.src"
      />
    </div>
    }
  </nz-carousel>
  <div class="button-wrap">
    <button nz-button  nzType="text" (click)="closeSlider.emit()" class="close">
        <span nz-icon nzType="close" nzTheme="outline"></span>
      </button>
      <button nz-button  nzShape="circle" nzType="default" (click)="carousel.next()" class="right">
        <span nz-icon nzType="chevron_right" nzTheme="outline"></span>
      </button>
  </div>
</div>