import { Injectable, inject } from '@angular/core';
import { FronteggAuthService } from '@frontegg/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { YomaTenant } from '../models/yomaTenant';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private subscription = new Subscription()
  fronteggAuthService = inject(FronteggAuthService)
  private userFeatures = new BehaviorSubject<YomaTenant>({} as YomaTenant);
  private user = new BehaviorSubject({});

  constructor() {
    this.fronteggAuthService.tenantsState$.pipe(takeUntilDestroyed()).subscribe(res => {
      if(res.activeTenant) {
        this.userFeatures.next(JSON.parse(res.activeTenant.metadata).features)
      }
    })
    this.subscription.add(
      this.fronteggAuthService.user$.subscribe(res => {
        if(res){
          this.user.next(res)
        }
      })
    );
  }

  public get getUserFeatures(): Observable<YomaTenant> {
    return this.userFeatures
  }

  public get getUser() : Observable<any>{
    return this.user;
  }

  hasFeature(feature: string) {
    return this.userFeatures.value.features?.includes(feature)
  }

  setFeature(features: any){
    this.userFeatures.next(features);
  }
}
