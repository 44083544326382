import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class FavoriteVehicleService {
  http = inject(HttpClient)
  favoriteChanged = new EventEmitter<boolean>()

  setFavorite(vehicleId: any): Observable<any> {
    let encoded = Buffer.from(vehicleId).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/vehicles/favourites/${encoded}`,null);
  }

  getFavorites(): Observable<any> {
    return this.http.get(`${environment.yomaApiURL}/vehicles/favourites`)
  }

  deleteFavorite(id: any): Observable<any> {
    let encoded = Buffer.from(id).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/vehicles/favourites/${encoded}`)
  }
}
