import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { NewsSource, RssNews } from 'src/app/models/news';

export interface RssData {
  count: number,
  data: RssNews[],
  image_logo_mapping: NewsSource
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  http = inject(HttpClient)

  getNews(page?: number, perPage?: number): Observable<RssData> {
    let httpParam = new HttpParams
    if (page) {
      httpParam = new HttpParams().set('page', page)
    } 
    if (perPage) {
      httpParam = httpParam.set('per_page', perPage)
    }
    return this.http.get<RssData>("https://rss-newsfeed-5be026ba941d.herokuapp.com/news", { params: httpParam });
  }
}
