<div class="register">
    <nz-card>
        <div class="title">
            <h3 nz-typography>Registrieren</h3>
        </div>
        <nz-divider></nz-divider>
        <div *ngIf="!result">
            <form nz-form [formGroup]="registrationForm" (ngSubmit)="submitForm()">
                <nz-form-item>
                    <nz-form-label nzRequired nzFor="email">E-mail</nz-form-label>
                    <nz-form-control nzErrorTip="The input is not valid E-mail!">
                        <input nz-input formControlName="email" id="email" />
                    </nz-form-control>
                </nz-form-item>
                <nz-space></nz-space>
                <nz-form-item>
                    <nz-form-control >
                        <div class="btn">
                            <button [nzLoading]="isLoading" [disabled]="!registrationForm.valid" nz-button nzType="primary" nzDanger>Registrieren</button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
        <nz-result [nzStatus]="status" *ngIf="result">
            <div nz-result-extra>
                <div [ngSwitch]="status">
                    <div *ngSwitchCase="'success'">
                        <p nz-typography>Um die Aktivierung abzuschließen, folgen Sie bitte den</p>
                        <p nz-typography>Anweisungen, die an Ihre Registrierungs-E-Mail gesendet werden.</p>
                        <h5 nz-typography style="text-align: center">Danke!</h5>
                        <img src="assets/logo.png" alt="yoma-track-trace">
                    </div>
                    <div *ngSwitchCase="'warning'">
                        <p nz-typography>Während des Registrierungsprozesses ist ein Fehler aufgetreten.</p>
                        <p nz-typography>Bitte kontaktieren Sie uns..</p>
                        <h5 nz-typography style="text-align: center">Danke!</h5>
                        <img src="assets/logo.png" alt="yoma-track-trace">
                    </div>
                    <div *ngSwitchCase="'error'">
                        <p nz-typography>Um fortzufahren, schließen Sie bitte die Aktivierung ab,</p>
                        <p nz-typography>Überprüfen Sie die Anweisungen in Ihrer Registrierungs-E-Mail.</p>
                        <h5 nz-typography style="text-align: center">Danke!</h5>
                        <img src="assets/logo.png" alt="yoma-track-trace">
                    </div>
                </div>
            </div>
        </nz-result>
    </nz-card>
</div>
