import { Component } from '@angular/core';

@Component({
  selector: 'tt-news-page',
  templateUrl: './news-page.component.html',
  styleUrl: './news-page.component.scss'
})
export class NewsPageComponent {

}
