import { addWeeks, getISOWeek, getISOWeekYear, addDays, format } from 'date-fns';

export class VehicleModel {
  from?: number;
  mid?: number;
  to?: number;
  id?: string;
  brand?: string;
  model?: string;
  quality?: number;
  url?: string
}

export class VehicleModels {
  brand?: string;
  from?: number;
  mid?: number;
  to?: number;
  quality?: number;
  url?: string;
  models?: VehicleModel[];
}

export class ExtendedVehicleModel {
  from?: number;
  mid?: number;
  to?: number;
  id?: string;
  brand?: string;
  model?: string;
  quality?: number;
  url?: string;
  variants?: ExtendedVehicleVariant[];
}
export class ExtendedVehicleVariant{
  from?: number;
  mid?: number;
  to?: number;
  id?: string;
  brand?: string;
  model?: string;
  quality?: number;
  url?: string;
  fuel_type?: string;
  history?: { last_month: { mid: number}}
}

export interface PredictionModel {
  id: string;
	url: string;
	brand: string;
	model: string;
	fuel_type: string;
	from: number;
	mid: number;
	to: number;
	quality: number;
	hist: PredictionHistory
}

export interface PredictionHistory {
  last_month: {
    mid: number
  }
}

export class PredictionModels implements PredictionModel {
  id!: string;
  url!: string;
  brand!: string;
  model!: string;
  fuel_type!: string;
  from!: number;
  mid!: number;
  to!: number;
  quality!: number;
  hist!: PredictionHistory;
  variants!: PredictionModel[]
  constructor (data: Partial<PredictionModels>) {
    Object.assign(this, data)
  }
}

export class PredictionModelData {
  from: string;
  to: string;
  year: string;
  dateFrom: string;
  dateTill: string;
  previousMonth: number;
  currnetMonth: number;
  firstColumn: string;
  secondColumn: string;

  constructor(item: PredictionModel) {
    this.from = getISOWeek(addWeeks(new Date(), item.from)).toString()
    this.dateFrom = format(addDays(new Date(), item.from * 7), 'dd.MM.yyyy')
    this.to = getISOWeek(addWeeks(new Date(), item.to)).toString()
    this.dateTill = format(addDays(new Date(), item.to * 7), 'dd.MM.yyyy')
    this.year = getISOWeekYear(addWeeks(new Date(), item.from)).toString()
    if (this.year != getISOWeekYear(addWeeks(new Date(), item.to)).toString()) {
      this.year += '/' + getISOWeekYear(addWeeks(new Date(), item.to)).toString()
    }
    this.previousMonth = Math.round(item.hist?.last_month?.mid)
    this.currnetMonth = Math.round(item.mid)
    let highestValue = Math.max(this.previousMonth, this.currnetMonth)
    this.firstColumn = (Math.round((100 * this.previousMonth) / highestValue) - 20).toString() + '%'
    this.secondColumn = (Math.round((100 * this.currnetMonth) / highestValue) - 20).toString() + '%'
    return this
  }
}
