import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router'
import {VehicleBrandService} from "../../services/vehicle-brand.service";
import { PredictionModel, PredictionModelData} from '../../models/vehicleModel';
import {VehicleModelService} from "../../services/vehicle-model.service";
import { format, sub } from 'date-fns';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-vehicle-model',
  templateUrl: './vehicle-model.component.html',
  styleUrls: ['./vehicle-model.component.scss']
})
export class VehicleModelComponent implements OnInit {

  vehicleModelID: string | null = "";
  vehicleBrandID: string | null = "";
  vehicleModel?: string = "";
  vehicleBrand?: string = "";
  allVehicles: Map<string, PredictionModel> = new Map<string, PredictionModel>()
  selectedPredictionModel: PredictionModel = {} as PredictionModel
  predictionModelData: PredictionModelData = {} as PredictionModelData
  trackingService = inject(TrackingService);
  isLoading = true

  constructor(private route: ActivatedRoute, private vehicleModelService: VehicleModelService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.vehicleModelID = params.get('modelID');
      this.vehicleBrandID = params.get('brandID');
    })
    this.vehicleModelService.getVehicleModelByID(this.vehicleModelID as string).subscribe({
      next: data => {
        data.variants.forEach((elem) => {
          // if(elem.fuel_type){
            this.allVehicles.set(elem.fuel_type!, elem)
          // }
        })
        if (data.variants.length > 1) {
          this.allVehicles.set('ALLE', data)
          this.selectedPredictionModel = data
        } else {
          this.selectedPredictionModel = data.variants[0]
        }
        this.predictionModelData = new PredictionModelData(this.selectedPredictionModel)
        this.isLoading = false
      },
      error: err => {
        this.isLoading = false
        console.log(err)
      }
    })
  }

  capitalize(str: string) {
    return str.charAt(0) + str.slice(1).toLocaleLowerCase()
  }

  modelChange(event: any) {
    this.predictionModelData = new PredictionModelData(event)
    this.trackingService.trackEvent(ACTIONS.neuwagenmonitor_fuel_type_changed, {value: event.fuel_type});
  }

  getMonth(months?: number) {
    if (months) {
      return format(sub(new Date(), {months: -months}), 'MMMM')
    }
    return format(new Date, 'MMMM')
  }
}
