import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayModule } from "@angular/cdk/overlay";
import { SliderContentComponent } from './slider-content/slider-content.component';


@Component({
  selector: 'tt-slider',
  standalone: true,
  imports: [CommonModule, NzIconModule, NzCarouselModule, NzButtonModule, NzModalModule, OverlayModule],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent implements OnInit {
  @Input() images: Array<any> = new Array()
  @HostListener("click") onClick(){
    this.openPortal()
  }

  overlay = inject(Overlay)

  overlayRef: OverlayRef = this.overlay.create({
    positionStrategy: this.overlay.position().global().left().top().centerHorizontally().centerVertically(),
    hasBackdrop: true,
    backdropClass: "tt-backdrop"
  })

  // If no images are provided this set will be used
  arrayOfImgUrls = [
    {src:'../../../assets/new-vehicle-teaser-image-1.png'},
    {src:'../../../assets/new-vehicle-teaser-image-2.png'},
    {src:'../../../assets/new-vehicle-teaser-image-3.png'},
  ]

  constructor() {
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach())
  }

  ngOnInit(): void {
    if(this.images.length == 0) {
      this.images = this.arrayOfImgUrls
    }
  }

  openPortal() {
    let component = this.overlayRef.attach(new ComponentPortal(SliderContentComponent))
    component.instance.images = this.images
    component.instance.closeSlider.subscribe(() => this.overlayRef.detach())
    this.overlayRef.keydownEvents().subscribe((event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight':
          component.instance.next()
          break;
        case 'ArrowLeft':
          component.instance.previous()
          break;
        case 'Escape':
          this.overlayRef.detach()
          break;
        default:
          break;
      }
    })
  }
}
