import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { CommonModule } from '@angular/common';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { Router } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { KeyAccount } from 'src/app/models/keyaccount';
import { KeyAccountService } from './key-account.service';
import { take } from 'rxjs';
import { Buffer } from 'buffer';

@Component({
  selector: 'tt-key-accounts',
  standalone: true,
  imports: [CommonModule, NzIconModule, NzInputModule, FormsModule, NzButtonModule, NzEmptyModule, NzTableModule, NzTagModule, NzFormModule, NzAlertModule, NzTypographyModule, NzSpinModule],
  templateUrl: './key-accounts.component.html',
  styleUrl: './key-accounts.component.scss'
})
export class KeyAccountsComponent implements OnInit {

  searchInputValue: any
  modal = inject(NzModalService);
  router = inject(Router)
  keyAccountService = inject(KeyAccountService)
  keyaccounts: Array<KeyAccount> | undefined
  isLoading = true
  isCRUD = false
  errorMsg = ''

  ngOnInit(): void {
    this.getKeyAccounts()
  }

  getKeyAccounts() {
    this.keyAccountService.getKeyAccounts().pipe(take(1)). subscribe(res => {
      this.keyaccounts = res
      this.isLoading = false
    })
  }

  log() {}

  createKeyAccount(ref: any) {
    this.isCRUD = true
    let data = ref.getConfig().nzData as KeyAccount
    this.keyAccountService.setKeyAccount(data).pipe(take(1)).subscribe({
      next: (res) => {
        this.isCRUD = false
        this.isLoading = true
        this.editKeyAccount(res.id);
        this.getKeyAccounts()
        ref.close()
      },
      error: (err) => {
        this.errorMsg = err.message
      }
    })
  }

  deleteKeyAccount(ref: NzModalRef) {
    this.isCRUD = true
    let id = ref.getConfig().nzData.id
    this.keyAccountService.deleteKeyAccount(Buffer.from(id).toString('base64')).subscribe({
      next: (res) => {
        this.isCRUD = false
        this.isLoading = true
        this.getKeyAccounts()
        ref.close()
      },
      error: (err) => { this.errorMsg = err.message }
    })
  }

  createKeyAccountModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item?: any): void {
    let nzTitle = 'Kunde oder Partner hinzufügen'
    let data = {}
    const modal: NzModalRef = this.modal.create({
      nzTitle: nzTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzData: data
    });
    modal.afterClose.pipe(take(1)).subscribe(() => this.errorMsg = "")
  }

  deleteKeyAccountModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item?: any): void {
    let nzTitle = 'Kunden & Partner löschen'
    let data = item
    const modal: NzModalRef = this.modal.create({
      nzTitle: nzTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzData: data
    });
    modal.afterClose.pipe(take(1)).subscribe(() => this.errorMsg = "")
  }

  editKeyAccount(id: string) {
    this.router.navigateByUrl(`keyaccount/${Buffer.from(id).toString('base64')}`)
  }

  isDisabled(ref: NzModalRef) {
    let data = ref.getConfig().nzData as KeyAccount
    return !(data.name && data.user_name && data.email)
  }

}
