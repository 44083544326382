import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VehicleBrand, VehicleBrands} from "../models/VehicleBrand";
import {Observable} from "rxjs";
import {VehicleModel} from "../models/vehicleModel";
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class VehicleBrandsService {

  constructor(private http: HttpClient) {
  }

  public getVehicleBrands(): Observable<VehicleBrands> {
    return this.http.get<VehicleBrands>(`${environment.fleetMonitorUrl}/brands/`)
  }

}
