import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {VehicleModel, VehicleModels} from "../models/vehicleModel";
import {Observable} from "rxjs";
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class VehicleBrandService {

  constructor(private http: HttpClient) {
  }
  public getVehicleBrandInfo(id: string): Observable<VehicleModels> {
    return this.http.get<VehicleModels>(`${environment.fleetMonitorUrl}/brands/${id}`)
  }

}
