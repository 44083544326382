import { CommonModule, JsonPipe } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCarouselComponent, NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'tt-slider-content',
  standalone: true,
  imports: [JsonPipe, CommonModule, NzIconModule, NzCarouselModule, NzButtonModule, A11yModule],
  templateUrl: './slider-content.component.html',
  styleUrl: './slider-content.component.scss'
})
export class SliderContentComponent {
  @Input() images: Array<any> = new Array()
  @Output() closeSlider: EventEmitter<void> = new EventEmitter();
  @ViewChild('carousel', { static: false }) carousel!: NzCarouselComponent;

  previous = () => this.carousel.pre()
  next = () => this.carousel.next()

}
