import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core'

import { AppModule } from './app/app.module';
import {environment} from "./environments/environment";


declare global {
  interface Window {
    analytics: any
    // showCollectorDialog: any
    // agencyCollectorDialog: any
    // supportCollectorDialog: any
    // ATL_JQ_PAGE_PROPS: any
    // __Zone_enable_cross_context_check: any
  }
}

// if (environment.production) {
//   enableProdMode()
  require('./segment.ts')
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
