import { Injectable } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  celebrate() {
    const duration = 3000; // in milliseconds
    confetti({
      particleCount: 300,
      spread: 360,
      origin: { y: 0.3 },
      colors: ['#FF4500', '#008080', '#FFD700'],
    });
    setTimeout(() => confetti.reset(), duration);
  }
}
