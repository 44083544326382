import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "Customer":
        return "Kunde";
        break
      case "Stock":
        return "Lager";
        break
      case "Other":
        return "Sonstige";
        break
      default: return "";
    }
  }

}
