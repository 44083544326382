<div class="page">
  <tt-fleet-prediction-banner/>
  <div class="vehicle-brand-content page-content">
    <nz-breadcrumb class="breadcrumb">
      <nz-breadcrumb-item><a routerLink="*">Home</a></nz-breadcrumb-item>
      <nz-breadcrumb-item><a routerLink="/neuwagenmonitor">Neuwagenmonitor</a></nz-breadcrumb-item>
      <nz-breadcrumb-item>{{vehicleBrand}}</nz-breadcrumb-item>
    </nz-breadcrumb>
    <p class="search-description-text">Wählen Sie das Modell, für das Sie eine detaillierte Vorhersage wünschen:</p>
    <tt-search-input
      placeholder="Search model name"
      (searchValue)="this.setFilteredVehicleData($event)"
    ></tt-search-input>
    <nz-space nzDirection="vertical" class="space-container">
      <nz-table
        #basicTable
        *ngIf="filteredVehicleData"
        [nzData]="filteredVehicleData"
        class="models-table"
        nzShowPagination="false"
        nzFrontPagination="false"
        [nzLoading] = "isLoading"
      >
        <thead>
          <tr>
            <th [nzSortFn]="sortColumn">{{vehicleBrand}} Modelle</th>
          </tr>
        </thead>
        <tbody>
          <tr data-testid="model" *ngFor="let data of basicTable.data" [routerLink]="['/neuwagenmonitor', brandID, data.id]" (click)="selectModel(data.model)">
            <td class="table-item">
              <div class="list-item-content">
                <div class="list-item-title">{{data.brand}} {{data.model}}</div>
                <div class="list-item-description">Durchschnittliche Vorlaufzeit: {{data.mid?.toFixed()}} Wochen</div>
              </div>
              <span nz-icon nzType="right-circle" nzTheme="outline" class="list-item-icon"></span>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-space>
  </div>
</div>
