import { Pipe, PipeTransform } from '@angular/core';
import { Buffer } from 'buffer';

@Pipe({
  name: 'url2base64',
  standalone: true
})
export class URL2base64Pipe implements PipeTransform {

  transform(value: string): string {
    return Buffer.from(value).toString('base64');
  }

}
