export class RssNews {
    title: string;
    link: string;
    description: string;
    img: string;
    source: string;
    pubDate: string;

    constructor(title: string, link: string, description: string, img: string, source: string, pubDate: string) {
        this.title = title;
        this.link = link;
        this.description = description;
        this.img = img
        this.source = source
        this.pubDate = pubDate
    }
}

export interface NewsSourceMap {
    name: string,
    src: string
}

export class NewsSource {
    [key: string] : NewsSourceMap
}