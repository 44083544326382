import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';


@NgModule({
    declarations: [
        SearchInputComponent
    ],
    exports: [
        SearchInputComponent
    ],
    imports: [
      CommonModule, NzInputModule, FormsModule, NzButtonModule
    ]
  })
  export class SearchInputModule { }