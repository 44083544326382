import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FronteggAppService, FronteggAuthService } from '@frontegg/angular';

export const authGuard: CanActivateFn = (route, state) => {
    const fronteggAppService = inject(FronteggAppService)
    const fronteggAuthService = inject(FronteggAuthService)
    const { isLoading } = fronteggAppService.fronteggApp.store.getState().auth;
    const redirectUrl = state.url;
    const router = inject(Router)

    const navigateToLoginIfNeeded = (redirectUrl: string): Promise<boolean> => {
        const { isAuthenticated } = fronteggAppService.fronteggApp.store.getState().auth;
        const { user } = fronteggAppService.fronteggApp.store.getState().auth
        if (isAuthenticated && !user?.activatedForTenant) {
            router.navigateByUrl('/register/notActivated')
            return Promise.resolve(false) // prevent navigation
        }
        if (!isAuthenticated) {
            localStorage.setItem('FRONTEGG_AFTER_AUTH_REDIRECT_URL', redirectUrl);
            fronteggAuthService.loginWithRedirect({ prompt: 'login' })
            return Promise.resolve(false) // prevent navigation
        }
        return Promise.resolve(true) // activate navigation
    }

    const waitForLoader = () => {
        return new Promise((resolve) => {
            const unsubscribe = fronteggAppService.isLoading$.subscribe((isLoading) => {
                if (!isLoading) {
                    resolve(true);
                    unsubscribe.unsubscribe();
                }
            });
        })
    }

    if (!isLoading) {
        // if showLoader false
        // check if user is authenticated
        return navigateToLoginIfNeeded(redirectUrl)
    }

    // wait for loader to finish and then check if user is authenticated
    return new Promise<boolean>(async (resolve) => {
        await waitForLoader()
        const activated = await navigateToLoginIfNeeded(redirectUrl)
        resolve(activated)
    })
};
