import { Component, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { take } from 'rxjs';
import { NzResultIconType } from 'ng-zorro-antd/result';
import { ActivatedRoute, Router } from '@angular/router';
import { FronteggAuthService } from '@frontegg/angular';

@Component({
  selector: 'tt-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent {
  registerService = inject(RegisterService)
  fronteggAuthService = inject(FronteggAuthService)
  registrationForm = new FormBuilder().group({
    email: [, [Validators.email, Validators.required]],
  })
  result: string | undefined
  status: NzResultIconType = '' as NzResultIconType
  isLoading = false
  route = inject(ActivatedRoute)

  constructor() {
    const status = this.route.snapshot.paramMap.get('status')

    if(status == 'notActivated') {
      this.status = 'error'
      this.result = 'notActivated'
    }

  }

  submitForm() {
    let email = this.registrationForm.get('email')?.value
    this.isLoading = true
    if(email) {
      this.registerService.register(email).pipe(take(1)).subscribe({
        next: () => {
          this.result = 'success'
          this.status = 'success'
          this.isLoading = false
        },
        error: (error: any) => {
          if(error.status == 403){
            this.fronteggAuthService.loginWithRedirect({login_hint: email, prompt: 'login' } )
          } else {
            this.status = 'warning'
            this.result = error.message
            this.isLoading = false
          }
        }
      })
    } else {
      this.isLoading = false
      return
    }
  }
}
