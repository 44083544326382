import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  http = inject(HttpClient)

  register(email: string): Observable<any> {
    const httpParams = new HttpParams().set('email', email);
    return this.http.put(`${environment.yomaApiURL}/noauth/register/guest`,null, { params: httpParams})
  }

}
